/*
* SVG icon url update
* */

function icons_load() {
    var url = $('body').data('svg-sprite-url');
    $.ajax({
        url: url,
        localCache: true,
        cacheTTL: 1,
        dataType: 'text',
        cacheKey: 'svg-sprite'
    }).done(function (response) {
        $('body').append('<div id="svg-icons-container" style="height: 0; width: 0; position: absolute; top: -99999px; left: 0; visibility: hidden;">' + response + '</div>');
    });
}


/*

 Loaders (for buttons)

 */

function loader_init() {
    $btn = $(".loader-on-page-load");
    loader_add($btn);
    $btn.prop("disabled", true);
}

function loader_add($o) {
    var loader = '<span class="loader"><svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"\
    width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">\
        <path fill="#fff" d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z">\
        <animateTransform attributeType="xml"\
            attributeName="transform"\
            type="rotate"\
            from="0 25 25"\
            to="360 25 25"\
            dur="0.6s"\
            repeatCount="indefinite"/>\
        </path>\
        </svg></div>';
    if ($o.closest('.btn').length) {
        $o.wrapInner("<div class='btn__loader-inner'>");
    }
    $o.append(loader).addClass("loading");
    var $loader = $o.find("> .loader");
    $loader.addClass("active");
}

function loader_remove($o) {
    $o.find(".btn__loader-inner").children().unwrap();
    $o.removeClass("loading");
    var $loader = $o.find("> .loader");
    $loader.removeClass("active");
    $loader.remove();
}


/*

Gallery and Modal Popups

 */

function fancybox_init() {

    if (!$("html").hasClass("fancybox-inited")) {

        $("html").addClass("fancybox-inited");

        $.fancybox.defaults.lang = $('html').attr('lang');

        if ($().fancybox) {
            $.fancybox.options_default = {
                slideShow: false,
                hash: false,
                loop: true,
                idleTime: 10,
                margin: [44, 0],
                gutter: 50,
                keyboard: true,
                animationEffect: "zoom",
                arrows: true,
                infobar: true,
                toolbar: true,
                buttons: [
                    'slideShow',
                    'fullScreen',
                    'thumbs',
                    'close'
                ],
                smallBtn: true,
                btnTpl: {
                    smallBtn: '<div data-fancybox-close class="fancybox-close"><svg class="icon icon--cross"><use xlink:href="#icon-cross"></use></svg></div>'
                },
                thumbs: {
                    autoStart: false,
                    hideOnClose: true
                }
            };

            $.fancybox.options_modal = {
                animationDuration: 700,
                parentEl: ".wrap",
                slideShow: false,
                hash: false,
                keyboard: false,
                ajax: {
                    settings: {
                        cache: false
                    }
                },
                hideScrollbar: true,
                baseClass: "fancybox-container--popup",
                trapFocus: false,
                autoFocus: false,
                touch: false,
                popup_default: true,
                btnTpl: {
                    smallBtn: '<div data-fancybox-close class="fancybox-close"><svg class="icon icon--cross"><use xlink:href="#icon-cross"></use></svg></div>'
                },
                afterLoad: function (instance, current) {
                    current.$content.wrap("<div>");
                    if (current.$content.hasClass('fancybox-content')) {
                        current.$content.removeClass('fancybox-content').parent().addClass('fancybox-content');
                    }
                    bind_widgets(current.$content);
                },
                afterShow: function (instance, current) {
                    if (typeof Blazy !== 'undefined' && typeof Blazy.revalidate !== 'undefined') {
                        Blazy.revalidate();
                    }
                },
                beforeClose: function (instance, current) {
                    if (current.$content) {
                        current.$content.closest(".fancybox-container").addClass('fancybox-is-close');
                    }
                },
            };

            $.fancybox.defaults.afterLoad = function (instance, current) {
                current.$content.closest('.fancybox-container').addClass('fancybox-container--' + current.type);
            };
            $.fancybox.defaults.beforeLoad = function (instance, current) {
                if ($().tooltipster) {
                    var tooltips = $.tooltipster.instances();
                    $.each(tooltips, function (i, tooltip) {
                        tooltip.close();
                    });
                }
            };
            $.fancybox.defaults.hash = false;
            $.fancybox.defaults.errorTpl = '<div><div class="panel panel--compact"><div class="panel__content text-align-center"><p>The requested content cannot be loaded. <br/> Please try again later.</p></div></div></div>';
        }

        $('body').on('mousewheel', function (e) {
            if ($(".fancybox-is-zoomable").length) {
                e.preventDefault();
                var instance = $.fancybox.getInstance();
                if ($(".fancybox-is-zoomable").length && instance.canPan() && e.deltaY > 0) {
                    instance.scaleToFit();
                } else if ($(".fancybox-can-zoomIn").length && instance.isScaledDown() && e.deltaY < 0) {
                    instance.scaleToActual(e.clientX, e.clientY);
                }
            }
        });
    }

    if ($().fancybox) {

        var options = $.fancybox.options_default;
        $fancybox_links_all = $("[data-fancybox]").not(".fancybox-inited");

        $fancybox_links = $fancybox_links_all.not("[data-type='ajax'], [data-type='inline']");
        fancybox_links_by_group = [];
        groups = [];
        $fancybox_links.each(function () {
            var group = $(this).attr("data-fancybox");
            if (!group) group = "";
            if ($.inArray(group, groups) < 0) groups.push(group);
        });
        for (group in groups) {
            options_current = $.extend(true, {}, options);
            var $items = $fancybox_links.filter("[data-fancybox='" + groups[group] + "']");
            var $first = $items.eq(0);
            if (typeof $first.attr("data-fancybox-loop") !== "undefined") {
                options_current["loop"] = $first.data("fancybox-loop");
            }
            $items.fancybox(options_current).addClass("fancybox-inited");
        }

        $fancybox_links_ajax = $fancybox_links_all.filter("[data-type='ajax'], [data-type='inline']");
        $fancybox_links_ajax.each(function () {
            var options = $.fancybox.options_modal;
            if ($(this).data('ajax-type')) {
                options = $.extend({}, options, {
                    ajax: {
                        settings: {
                            type: $(this).data('ajax-type'),
                        }
                    }
                });
            }
            if ($(this).data('ajax-data')) {
                options = $.extend({}, options, {
                    ajax: {
                        settings: {
                            data: $(this).data('ajax-data'),
                        }
                    }
                });
            }
            if ($(this).data('popup-class')) {
                options = $.extend({}, options, {
                    baseClass: options.baseClass + ' ' + $(this).data('popup-class')
                });
            }
            $(this).fancybox(options);
        }).addClass("fancybox-inited");
    }
}



/*

Tabs

 */

function tabs_init() {

    $(document).on("click", ".js-tabs a", function (e, eparams) {
        var $tabs = $(this).closest(".js-tabs");

        $(this).parent().siblings().removeClass("active");
        if ($(this).closest(".js-tabs-toggle").length) {
            $(this).parent().toggleClass("active");
        } else {
            $(this).parent().addClass("active");
        }

        tabs_update_pointer($tabs);

        $ostt = $(this).closest(".js-tabs-onclick-scroll-to-tabs");
        if ($ostt.length) {
            $("html, body").stop(true, true).animate({scrollTop: $ostt.offset().top + ($ostt.data("tabs-scroll-offset") ? $ostt.data("tabs-scroll-offset") : 0) - ($ostt.data("tabs-scroll-offset-element") ? $($ostt.data("tabs-scroll-offset-element")).outerHeight() : 0)}, 500);
        }

        if ($(this).hasClass("js-tabs-ignore") || $tabs.hasClass("js-tabs-ignore")) {
            return true;
        }

        var $o = $($(this).attr("href"));

        if ($o.length) {
            e.preventDefault();
            if ($(this).closest(".js-tabs-toggle").length) {
                $o.toggleClass("active");
            } else {
                $o.addClass("active");
            }

            $o.siblings(".tab-pane").removeClass("active");
            if ($o.hasClass("active")) {
                $o.closest(".tab-content-change-height").css("height", 0).css("height", $o.outerHeight());
                $o.trigger('tabs-show');
            } else {
                $o.closest(".tab-content-change-height").css("height", 0);
                $o.trigger('tabs-hide');
            }
            if ($(this).closest(".js-tabs").hasClass("js-tabs-hash-change")) {
                if (typeof eparams === "undefined" || typeof eparams.nohash === "undefined" || !eparams.nohash) {
                    location_hash_update($(this).attr("href"));
                }
            }
            $o.filter(".active").find(":input:first").trigger("check-form");
            $o.siblings(".tab-pane").find(":input.error").each(function () {
                var $form = $(this).closest("form");
                var validator = $form.data("validator");
                if (validator) $(this).valid();
            });
            $(window).trigger("scroll.fixed-hscroll");
        }
    });

    $(document).on("tabs-show", function (e) {
        if (typeof Blazy !== 'undefined' && typeof Blazy.revalidate !== 'undefined') {
            Blazy.revalidate();
        }
    });

    tabs_scroll_init();
    // $(document).ajaxStop(function () {
    //     tabs_scroll_init();
    // });

    if (location.hash) {
        if ($(location.hash).filter(".tab-pane").length) {
            $(".js-tabs a[href='" + location.hash + "']").trigger("click");
        } else if ($(".tab-pane").find(location.hash).length) {
            $(".js-tabs a[href='#" + $(".tab-pane").find(location.hash).closest(".tab-pane").attr("id") + "']").trigger("click", {nohash: true});
        }
    }

    tabs_update_pointer($(".tabs"));
    $(".js-tabs-fixed-center a").on("click-tabs-fixed-center", function (e) {
        tabs_update_pointer($(this).closest(".tabs"));
    });
    $(window).on("resize orientationchange", function () {
        tabs_update_pointer($(".tabs"));
        $(".js-tabs-fixed-center-scroll").trigger("scroll.emulate");
    });
}

function tabs_scroll_init() {
    $(".js-tabs-fixed-center-scroll").not(".js-tabs-fixed-center-scroll-inited").on("scroll scroll.emulate", function (e) {
        var $tabs = $(this).closest(".js-tabs-fixed-center");
        var direction = ($(this).css('overflow-x') == 'auto') ? 'horz' : 'vert';
        var scroll_val = (direction == 'vert') ? $(this).scrollTop() : $(this).scrollLeft();
        var scroll_size = (direction == 'vert') ? $(this).outerHeight() : $(this).outerWidth();
        var scroll_inner_size = (direction == 'vert') ? $(this)[0].scrollHeight : $(this)[0].scrollWidth;
        if (scroll_val <= 0) {
            $tabs.addClass("scroll-in-start");
        } else {
            $tabs.removeClass("scroll-in-start");
        }
        if (scroll_val + scroll_size >= scroll_inner_size - 1) {
            $tabs.addClass("scroll-in-end");
        } else {
            $tabs.removeClass("scroll-in-end");
        }
    }).addClass("js-tabs-fixed-center-scroll-inited").trigger("scroll.emulate").closest(".js-tabs-fixed-center").addClass('js-tabs-fixed-center-inited');

    $(".js-tabs-fixed-center a").not(".js-tabs-fixed-center-scroll-inited").on("click click-tabs-fixed-center", function (e) {
        var $tabs = $(this).closest(".js-tabs-fixed-center");
        var $ul = $(this).closest(".js-tabs-fixed-center-scroll");
        $ul.stop(true, true).animate({scrollLeft: $(this).parent()[0].offsetLeft - $tabs.width() / 2 + $(this).outerWidth() / 2}, 300);
    }).addClass("js-tabs-fixed-center-scroll-inited");

    $(".js-tabs-scroll-prev").not(".js-tabs-fixed-center-scroll-inited").on("click", function (e) {
        e.preventDefault();
        var $tabs = $(this).closest(".js-tabs-fixed-center");
        var $ul = $tabs.find(".js-tabs-fixed-center-scroll");
        $ul.stop(true, true).animate({scrollLeft: $ul.scrollLeft() - $ul.width() * 0.75}, 300);
    }).addClass("js-tabs-fixed-center-scroll-inited");

    $(".js-tabs-scroll-next").not(".js-tabs-fixed-center-scroll-inited").on("click", function (e) {
        e.preventDefault();
        var $tabs = $(this).closest(".js-tabs-fixed-center");
        var $ul = $tabs.find(".js-tabs-fixed-center-scroll");
        $ul.stop(true, true).animate({scrollLeft: $ul.scrollLeft() + $ul.width() * 0.75}, 300);
    });

    $(".js-tabs-fixed-center li.active a").trigger("click-tabs-fixed-center");
}

function tabs_update_pointer($tabs) {
    $tabs.each(function () {
        var $pointer = $(this).find(".js-tabs-pointer");
        var $scroll = $pointer.closest(".tabs__scroll");
        var scroll_offset = 0;
        if ($pointer.length) {
            if ($scroll.length) {
                scroll_offset = $scroll[0].scrollLeft;
            }
            $pointer.css({
                left: $(this).find("li.active").offset().left - $(this).offset().left + scroll_offset,
                width: $(this).find("li.active").width(),//outerWidth
            }).addClass("active");
        }
    });
}

