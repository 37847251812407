
/*

 Click and touch events helpers

 */

function click_touch_init() {

    $(document).on("click.touchfocused", ".touchevents .touch-focused, .touch-focused-all", function (e) {
        var $o = $(this);
        if (!$o.hasClass("focused")) {
            if (!$o.hasClass("disable-prevent-link-clicks")) {
                e.preventDefault();
            }
            $(".touch-focused").not($o).not($o.closest(".touch-focused")).removeClass("focused");
            $o.addClass("focused");
        }
        else {
            if ($o.hasClass('touch-focused-toggle')) {
                $o.removeClass("focused");
            }
        }
    });

    $(document).on("click", ".touchevents .touch-focused a, .touch-focused-all a", function (e) {
        var $tf = $(this).closest(".touch-focused");
        if (!$tf.hasClass("focused") && !$tf.hasClass("disable-prevent-link-clicks")) {
            e.preventDefault();
        }
    });

    $(document).on("click touchstart", "*", function (e) {
        if (!$(e.target).closest(".touch-focused").length) {
            $(".touch-focused").removeClass("focused");
        }
    });

    $(document).on("click", ".no-click", function (e) {
        e.preventDefault();
    });

    $(document).on("click", ".no-propagation", function (e) {
        e.stopPropagation();
    });

}



/*

Responsive design helpers

 */

function responsive_init() {
    responsive_update();
}

function responsive_update(force, no_animation) {
    if (typeof no_animation !== 'undefined' && no_animation) {
        $("body").addClass('no-transition no-animation');
    }
    if (typeof force === "undefined") force = false;
    var ww = window.innerWidth;
    if ($("body").data("ww") != ww || force)
    {
        if (typeof Blazy !== 'undefined' && typeof Blazy.revalidate !== 'undefined') {
            Blazy.revalidate();
        }
        $("[data-place]").each(function(){
            var places = $(this).data("place");
            var breakpoints = Object.keys(places).map(function(value) {
                return parseInt(value);
            }).sort(function(a,b) {
                return a - b;
            }).reverse();
            for (i in breakpoints) {
                if (window.matchMedia("(min-width: "+breakpoints[i]+"px)").matches) {
                    if ($(places[breakpoints[i]]).length)  {
                        if (!$(this).next(places[breakpoints[i]]).length) {
                            $(this).attr("data-place-breakpoint", breakpoints[i]).insertBefore(places[breakpoints[i]]);
                        }
                    }
                    break;
                }
            }
        });

        $("[data-placeholder-mobile]").each(function() {
            if (window.matchMedia("(max-width: 767px)").matches) {
                if (typeof $(this).attr('data-placeholder-desktop') === 'undefined') {
                    $(this).attr('data-placeholder-desktop', $(this).attr('placeholder'));
                }
                $(this).attr('placeholder', $(this).attr('data-placeholder-mobile'));
            }
            else {
                if (typeof $(this).attr('data-placeholder-desktop') !== 'undefined') {
                    $(this).attr('placeholder', $(this).attr('data-placeholder-desktop'));
                    $(this).removeAttr('data-placeholder-desktop');
                }
            }
        });

        var width = 0;
        $(".tltgb__title").each(function() {
            if (!$(this).data('fdefault')) {
                $(this).addClass('ready').data('fdefault', parseInt($(this).css('font-size'), 10));
            }
            var ratio = $(this).data('fdefault')/232;
            var $g = $(this).closest('.tltgb__group');
            if (!width) {
                width = Math.floor($g.width());
            }
            $(this).css('font-size', ratio*width+'px');
            console.log(width, $(this).data('fdefault'));
        });

        $("body").trigger("responsive-update");

        $("body").data("ww", ww);
    }
    if (typeof no_animation !== 'undefined' && no_animation) {
        $("body")[0].offsetHeight;
        $("body").removeClass('no-transition no-animation');
    }
}


/*

Scroll Lazy Loading

 */

function blazy_init() {

    $('.js-blazy-revalidate-on-scroll').on('scroll', function(){
        blazy_revalidate_on_scroll_delay(function(){
            if (typeof Blazy !== 'undefined' && typeof Blazy.revalidate !== 'undefined') {
                Blazy.revalidate();
            }
        }, 50);
    });

    window.Blazy = new Blazy({
        offset: 0,
        success: function(ele){
            var $o = $(ele).closest(".img-to-bg");
            img_to_bg($o);
            $o.addClass('img-to-bg-lazy-loaded');
            //console.log('Blazy', ele, 'success');
            blazy_callback(ele);
        }
        , error: function(ele, msg){
            //console.log('Blazy', ele, msg);
            blazy_callback(ele);
            if(msg === 'missing'){
                //console.log(ele, msg);
            }
            else if(msg === 'invalid'){
                //console.log(ele, msg);
            }
        }
    });

    $(window).on('resize', function(){
        blazy_revalidate_delay(function(){
            blazy_revalidate();
        }, 100);
    });

    $(document).on('shown.bs.modal', function () {
        blazy_revalidate();
    });

    $('.js-slick-slider-revalidate-image').on('init reInit setPosition', function(event){
        blazy_revalidate();
    });
}

function blazy_callback(ele) {
    var callback = $(ele).not('.blazy-callback-executed').data('src-callback');
    if (callback) {
        var callback_params = $(ele).data('src-callback-params');
        if (!callback_params) {
            callback_params = [];
        }
        var fn = getFunctionFromString(callback);
        if (typeof fn === "function") {
            $(ele).addClass('blazy-callback-executed');
            fn.apply(ele, callback_params);
        }
    }
}

function blazy_revalidate() {
    if (typeof Blazy !== 'undefined' && typeof Blazy.revalidate !== 'undefined') {
        Blazy.revalidate();
    }
}

var blazy_revalidate_delay = (function () {
    var timer = 0;
    return function (callback, ms) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
    };
})();

var blazy_revalidate_on_scroll_delay = (function () {
    var timer = 0;
    return function (callback, ms) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
    };
})();

// Get function from string, with or without scopes (by Nicolas Gauthier)
window.getFunctionFromString = function(string)
{
    var scope = window;
    var scopeSplit = string.split('.');
    for (i = 0; i < scopeSplit.length - 1; i++)
    {
        scope = scope[scopeSplit[i]];

        if (scope == undefined) return;
    }

    return scope[scopeSplit[scopeSplit.length - 1]];
};




/*

Set Background Image depending on img content inside it

 */

function img_to_bg($o, context) {

    if (!$("body").hasClass("img-to-bg-inited")) {

        $(window).on("resize", function(){
            delay_img_to_bg(function(){
                img_to_bg($(".img-to-bg-resp"));
            }, 100);
        });

        $("body").addClass("img-to-bg-inited");
    }

    if (typeof $o === "undefined" || !$o) $o = $(".img-to-bg", context);
    $o.each(function () {
        var $imgtobg = $(this);
        var $img = $imgtobg.find("> img").first();
        if (!$img.length) {
            $img = $imgtobg.find("> picture img").first();
        }
        var src = "";
        if ($img.length) {
            var src = $img[0].currentSrc;
        }
        if (!src) {
            src = $img.attr('src');
        }
        if ($img.length && src) {
            $imgtobg.css("background-image", "url('" + src + "')");
            $imgtobg.addClass("img-to-bg--inited");
        }
    });
}

var delay_img_to_bg = (function(){
    var timer = 0;
    return function(callback, ms){
        clearTimeout (timer);
        timer = setTimeout(callback, ms);
    };
})();


/*

Scroll to needed objects

 */

function goto_init()
{
    $(document).on("click", ".js-goto", function (e) {
        var href = $(this).attr("href");
        var hash = href.split('#');
        if (hash.length > 1) {
            hash = '#'+hash.pop();
            var $o = $(hash);
            if ($o.length) {
                e.preventDefault();
                var $this = $(this);
                if ($this.closest(".goto-list").length)
                {
                    $this.closest(".goto-list").find("li").removeClass("active");
                    $this.closest("li").addClass("active");
                    tabs_update_pointer($this.closest(".tabs"));
                }
                if ($this.closest(".goto-hash-change").length)
                {
                    location_hash_update($this.attr("href"));
                }
                goto_object($o);
            }
        }
    });
}

function goto_object($o, speed, doffset)
{
    if (typeof speed === 'undefined') {
        speed = 500;
    }
    if ($o.length)
    {
        var offset = $o.offset().top;
        if (typeof doffset !== 'undefined') {
            offset += doffset;
        }
        var $offset = $(".js-goto-offset").first();
        if ($offset.length) {
            offset -= $offset.outerHeight();
        }
        if ($o.data("goto-offset-element")) {
            offset -= $($o.data("goto-offset-element")).outerHeight();
        }
        goto_offset(offset, speed, $o);
    }
}

function goto_offset(offset, speed, $o)
{
    if (typeof speed === 'undefined') {
        speed = 500;
    }
    $("html, body").stop(true, true).animate({scrollTop: offset}, speed, function(){
        $(this).trigger('goto-end');
        if ($o) {
            $o.trigger('goto-end');
        }
    });
}


/*

Actions on Scroll

 */

function scroll_animations() {
    body_scrolled();
}

function body_scrolled() {
    requestAnimationFrame(function(){
        var $o = $(".js-header-on-scroll");
        if ($o.length) {
            // var st = $(window).scrollTop();
            // var scrolled_condition = st > 0;
            // var wh = window.innerHeight;
            // if (scrolled_condition) {
            //     if (!$o.hasClass("scrolled")) $o.addClass("scrolled");
            // }
            // else {
            //     if ($o.hasClass("scrolled")) $o.removeClass("scrolled");
            // }
            // var scrolled_condition = st > wh;
            // if (scrolled_condition) {
            //     if (!$o.hasClass("scrolled-fold")) $o.addClass("scrolled-fold");
            // }
            // else {
            //     if ($o.hasClass("scrolled-fold")) $o.removeClass("scrolled-fold");
            // }
            scroll_back_header($o);
        }
    });
}

function scroll_back_header($o) {
    var y_offset = window.pageYOffset;
    this.menuScrolled = y_offset > 0;
    this.menuHidden = y_offset > $('.header-replace').height() && y_offset > this.oldScrollPosition;
    if (y_offset != this.oldScrollPosition) {
        $o.toggleClass("header--hidden", this.menuHidden);
        $o.toggleClass("header--scrolled", this.menuScrolled);
    }
    this.oldScrollPosition = y_offset;
}

function scroll_back_header_hide($o) {
    var y_offset = window.pageYOffset;
    this.menuHidden = y_offset > $('.header-replace').height();
    $o.toggleClass("header--hidden", this.menuHidden);
}



/*

Header Init

*/

function header_init() {

    $(document).on("click", ".js-menu-switcher", function (e) {
        e.preventDefault();
        $("html").toggleClass("menu-overlay-active");
        if ($("html").hasClass("menu-overlay-active")) {
            bodyScrollLock.disableBodyScroll($('.js-menu-overlay .menu-overlay__content')[0]);
        }
        else {
            bodyScrollLock.enableBodyScroll($('.js-menu-overlay .menu-overlay__content')[0]);
        }
    });

    $(document).on("click", ".js-menu-hide", function (e) {
        e.preventDefault();
        menu_overlay_hide();
    });

    $(document).on("click", "*", function (e) {
        if (!$(e.target).closest(".js-menu-overlay, .js-menu-switcher").length) {
            if ($("html").hasClass("menu-overlay-active")) {
                menu_overlay_hide();
            }
        }
    });

    $(document).on("touchmove", ".js-menu-overlay", function (e) {
        e.stopPropagation();
    });

    $(window).on("scroll", function (e) {
        if ($("html").hasClass("menu-overlay-active")) {
            menu_overlay_hide();
        }
    });


    $(document).on("click", ".js-menu2-switcher", function (e) {
        e.preventDefault();
        $("html").toggleClass("menu2-overlay-active");
        if ($("html").hasClass("menu2-overlay-active")) {
            bodyScrollLock.disableBodyScroll($('.js-menu2-overlay .menu-overlay__content')[0]);
        }
        else {
            bodyScrollLock.enableBodyScroll($('.js-menu2-overlay .menu-overlay__content')[0]);
        }
    });

    $(document).on("click", ".js-menu2-hide", function (e) {
        e.preventDefault();
        menu2_overlay_hide();
    });

    $(document).on("click", "*", function (e) {
        if (!$(e.target).closest(".js-menu2-overlay, .js-menu2-switcher").length) {
            if ($("html").hasClass("menu2-overlay-active")) {
                menu2_overlay_hide();
            }
        }
    });

    $(document).on("touchmove", ".js-menu2-overlay", function (e) {
        e.stopPropagation();
    });

    $(window).on("scroll", function (e) {
        if ($("html").hasClass("menu2-overlay-active")) {
            menu2_overlay_hide();
        }
    });
}

function menu_overlay_hide() {
    $("html").removeClass("menu-overlay-active");
    bodyScrollLock.enableBodyScroll($('.js-menu-overlay .menu-overlay__content')[0]);
}

function menu2_overlay_hide() {
    $("html").removeClass("menu2-overlay-active");
    bodyScrollLock.enableBodyScroll($('.js-menu2-overlay .menu-overlay__content')[0]);
}



/*

Hide/Show blocks

 */

function toggle_element_init()
{
    $(document).on("click click-pseudo change toggle-hide", ".toggle-element, .show-element, .hide-element", function (e) {
        if ($(this).is("a")) e.preventDefault();
        if (e.type != "change" && $(this).is(":checkbox,:radio")) {
            return true;
        }
        var $o = $();
        if ($(this).attr("href") && $(this).attr("href").length > 1) {
            $o = $($(this).attr("href"));
        }
        if (!$o.length) {
            $o = $($(this).data("selector"));
        }
        var restore_scroll = $(this).data("toggle-restore-scroll-position");
        if (restore_scroll) {
            var scroll_pos = $(window).scrollTop();
            var $scroll_relative = $(this);
            var scroll_relative = $(this).data('toggle-restore-scroll-relative');
            if (scroll_relative) {
                $scroll_relative = $(scroll_relative);
            }
            var offset_pos = parseInt($scroll_relative.offset().top, 10);
        }
        var class_name = "hidden";
        if ($(this).data("toggle-class")) {
            class_name = $(this).data("toggle-class");
        }
        var element_class_name = "active";
        if ($(this).data("toggle-element-class")) {
            element_class_name = $(this).data("toggle-element-class");
        }
        var change_label = false;
        if (($(this).hasClass("toggle-element") && e.type != "toggle-hide") || (e.type == "toggle-hide" && $o.hasClass(element_class_name))) {
            change_label = true;
            if ($(this).is(":checkbox,:radio")) {
                if (e.type == "change") {
                    $o.toggleClass(class_name, !$(this).prop("checked"));
                }
            }
            else {
                $(this).toggleClass(element_class_name);
                $o.toggleClass(class_name);
            }
            $o.data('toggle-class', class_name);
            $o.data('toggle-element-class', element_class_name);
            $o.data('toggle-element', $(this));
            $o.trigger("toggle-element");
        }
        if ($(this).data("toggle-close-other") && e.type != "toggle-hide") {
            var group = $(this).data("toggle-group");
            $('.toggle-element, .show-element').not(this).filter('[data-toggle-group="'+group+'"]').each(function(){
                $(this).trigger('toggle-hide');
            });
        }
        if ($(this).hasClass("show-element")) {
            $o_s = $($(this).data("selector-show"));
            if ($o_s.length) {
                $o = $o_s;
            }
            if (!$(this).hasClass(element_class_name)) change_label = true;
            $(this).addClass(element_class_name);
            $o.toggleClass(class_name, !!$(this).data("toggle-reverse"));
            $o.trigger("show-element");
        }
        if ($(this).hasClass("hide-element")) {
            $o_h = $($(this).data("selector-hide"));
            if ($o_h.length) {
                $o = $o_h;
            }
            if ($(this).hasClass(element_class_name)) change_label = true;
            $(this).addClass(element_class_name);
            $o.toggleClass(class_name, !$(this).data("toggle-reverse"));
            $o.trigger("hide-element");
        }
        if (change_label && $(this).data("toggle-change-label")) {
            var label = $(this).html();
            $(this).html($(this).attr("data-toggle-change-label"));
            $(this).attr("data-toggle-change-label", label);
        }
        var $siblings = $(this).siblings();
        if ($(this).data("toggle-siblings-selector")) {
            $siblings = $(this).closest($(this).data("toggle-siblings-selector")).siblings();
        }
        if ($(this).data("toggle-current-selector")) {
            $siblings = $siblings.find($(this).data("toggle-current-selector"));
        }
        $siblings.removeClass(element_class_name);
        if (restore_scroll) {
            var scroll_pos_new = $(window).scrollTop();
            var offset_pos_new = parseInt($scroll_relative.offset().top, 10);
            $(window).scrollTop(offset_pos_new - (offset_pos - scroll_pos));
        }
    });

    $(document).on("click", "*", function (e) {
        if (!$(e.target).closest(".toggle-element").length && !$(e.target).closest(".toggle-element-object").length) {
            $(".toggle-element-object[data-toggle-element-close-on-blur]").each(function(){
                var classname = $(this).data('toggle-class');
                if (!classname) classname = 'hidden';
                $(this).removeClass(classname);
                if ($(this).data('toggle-element')) {
                    $($(this).data('toggle-element')).removeClass($(this).data('toggle-element-class'));
                }
            });
            //e.stopPropagation();
        }
    });
}



function misc_init() {

    $(document).on('show-element', function(){
        responsive_update(true);
    });

    $(document).on('click', '.js-tag-add', function (e) {
        e.preventDefault();
        var $container = $($(this).data('container'));
        var $input = $($(this).data('input'));
        var values = $.trim($input.val()).split(/\s+/);
        $.each(values, function(k,v){
            if (v) {
                var $item = $($('.js-tag-template').html());
                $item.removeClass('js-tag-template hidden').find('[data-name]').html(v).end().find('input').val(v);
                $container.append($item);
            }
        });
        $input.val('');
        var $input = $(this).closest('form').find('.js-tag-input');
        if ($input.length) {
            $input.valid();
        }
    });

    $(document).on('click', '.js-tag-remove', function(){
        $(this).closest('.js-tag').remove();
        var $input = $(this).closest('form').find('.js-tag-input');
        if ($input.length) {
            $input.valid();
        }
    });


}