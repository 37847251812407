
$(function () {
    document_ready();
    window_onload();
});

function document_ready() {
    ts = (new Date()).getTime();

    responsive_init();

    $("body").addClass("ready").removeClass("no-transition");

    init_event_handlers();
    bind_widgets();

    te = (new Date()).getTime();
    console.log("On-Ready Load Time: ", te - ts);
}

function window_onload() {
    ts = (new Date()).getTime();
    $("body").removeClass("not-loaded").addClass("loaded");

    //responsive_update();

    te = (new Date()).getTime();
    console.log("Window Load Time: ", te - ts);
}

$(window).scroll(function () {
    scroll_animations();
});

$(window).on("resize", function (e) {
    responsive_update(null, true);
    scroll_animations();
});

function init_event_handlers() {
    icons_load();
    blazy_init();
    goto_init();
    scroll_animations();
    fix_touch_hovers();
    click_touch_init();
    header_init();
    form_process_init();
    tabs_init();
    toggle_element_init();
    misc_init();
}

function bind_widgets(context) {
    img_to_bg(context);
    validate_init();
    fancybox_init(context);
    checkbox_plain_init(null, context);
}